import React, { Component } from 'react';
import { CardColumns } from 'react-bootstrap';

import base from '../config/base';
import CardTraining from './CardTraining';

import '../css/training.css';

class Training extends Component {
  state = {
    training: {},
    teachers: {}
  };

  componentDidMount() {
    this.ref = base.syncState('/training', {
      context: this,
      state: 'training'
    });
    this.ref = base.syncState('/teachers', {
      context: this,
      state: 'teachers'
    })
  }

  render() {
    const { training } = this.state;

    /**
     * Fonction pour trier le state Training par ordre alphabétique des Titres 
     */
    const sortByTitle = (a, b) => {
      if (this.state.training[a].title < this.state.training[b].title) {
        return -1;
      } else if (this.state.training[a].title === this.state.training[b].title) {
        return 0;
      } else {
        return 1;
      }
    }

    const listTraining = Object.keys(training)
      .sort(sortByTitle)
      .map(key => (
        <CardTraining
          key={key}
          id={key}
          item={this.state.training[key]}
          teachers={this.state.teachers}
        />
      ));

    return (
      <div id="training" className="box">
        <h1>Formations</h1>
        <CardColumns className="container-training">
          {listTraining}
        </CardColumns>
      </div>
    );
  }
}

export default Training;