import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLink } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'react-bootstrap';

import '../css/cardExperience.css';

const CardExperience = ({ details }) => {

  const dateXp = details => {
    if (details.dateEnd) {
      return `${details.dateStart} - ${details.dateEnd}`;
    } else {
      return `${details.dateStart} - En poste actuellement`;
    }
  };

  const infos = details.infos
    .split('\n')
    .map(item => <li key={item}>{item}</li> );

  const skills = details.skills
    .split(';')
    .sort()
    .map(item => <li key={item}><Badge pill variant="primary">{item}</Badge></li>);
  

  return (
    <div className="d-flex flex-column xp-content">
      <div className="d-flex flex-row w-100 justify-content-center flex-wrap flex-md-nowrap">
        <div className="left">
          <div className="xp-box">
            <div className="xp-compagny">{details.compagny}</div>
            <p>
              <span>{dateXp(details)}</span>
              <a className="d-none d-md-inline" href={details.link} target="_blank" rel="noopener noreferrer">
                Site internet de l'entreprise
                <sup className="ml-1"><FontAwesomeIcon icon={faLink} /></sup>
              </a> 
            </p>
            { details.logo && <img className="d-none d-md-block" src={`./images/experiences/${details.logo}`} alt={details.compagny} />}
            <p className="d-none d-md-flex">{details.city}</p>
          </div>
        </div>
        <div className="middle">
          <div className="rod rod-one">
            <span className="case-left"></span>
            <span className="case-right"></span>
          </div>
          <span className="cross"><FontAwesomeIcon icon={faTimes} /></span>
          <div className="rod rod-two">
            <span className="case-left"></span>
            <span className="case-right"></span>
          </div>
        </div>
        <div className="right">
          <div className="xp-text">
            <div className="xp-job">{details.job}</div>
            <p>{details.description}</p>
            <ul className="xp-infos">
              {infos}
            </ul>
            <ul className="xp-skills">
              {skills}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
};

export default CardExperience;