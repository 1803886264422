import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faSymfony } from '@fortawesome/free-brands-svg-icons';

import portrait from './../images/portrait.jpg';
import '../css/presentation.css';
import { firebaseApp } from '../config/base';

class Presentation extends Component {
  state = {
    lastUpdate: '',
  }

  componentDidMount = () => {
    firebaseApp.database().ref().on('value', (data) => {
      let date = data.val().lastUpdate;
      this.setState({lastUpdate: date})
    })
  }

  render() {

    return (
      <div id="presentation">
        <Card>
          <div className="row no-gutters">
            <div className="d-none d-lg-flex col-lg-4 card-container-img">
              <img src={portrait} alt="Portrait de Cédric Pourrias" className="card-img" />
            </div>
            <div className="col-lg-8">
              <Card.Body>
                <Card.Title>Cédric Pourrias</Card.Title>
                <Card.Subtitle>
                  <FontAwesomeIcon icon={faReact} />
                  <em className="text-center">Développeur Web - Full Stack</em>
                  <FontAwesomeIcon icon={faSymfony} />
                </Card.Subtitle>
                <ul>
                  <li>L'univers de l'informatique a toujours été une passion, notament la programmation.</li>
                  <li>Ce fut tardivement mais avec détermination que je me suis orienté dans une reconversion professionnelle afin de concrétiser ma passion en métier. </li>
                  <li>Disponible pour un poste de développeur Web et ouvert pour de nouveaux défis.</li>
                  <li>Je travail dur pour gagner en compétences, être informé sur les nouvelles technologies afin de garantir un perfectionnement, une sécuritée sur mes projets et garantir l'excellence dans mon travail!</li>
                  <li>Soif d'apprendre, de tomber sur des erreurs, problèmes pour les résoudres me permettant ainsi de gagner en confiance, satisfaction personnelle, et impatience de tomber sur le prochain pour le résoudre. Sinon, ce n'est pas drôle ! <span role="img" aria-labelledby="Smiley aux yeux en coeur">&#x1F60D;</span></li>
                </ul>
              </Card.Body>
            </div>
          </div>
          <Card.Footer className="text-right">Dernière mise à jour : <span>{this.state.lastUpdate}</span> </Card.Footer>
        </Card>
      </div>
    );
  }
};

export default Presentation;