import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faMarkdown } from '@fortawesome/free-brands-svg-icons';
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';

import base from '../config/base';
import Loading from './Loading';

import '../App.css';
import '../css/projects.css';

class Project extends Component {
  state = {
    projects: {},
  }

  componentDidMount() {
    const url = this.props.match.url.split('/')[1];
    console.log(url);

    switch (url) {
      case 'eval':
        this.ref = base.syncState('/evaluations', {
          context: this,
          state: 'projects'
        });
        break;

      case 'project':
        this.ref = base.syncState('/projects', {
          context: this,
          state: 'projects'
        });
        break

      default:
        break;
    }
  }

  render() {
    if (Object.keys(this.state.projects).length === 0) {
      return (
        <Loading />
      )
    } else {
      const info = Object.values(this.state.projects)
        .find(evaluation => evaluation.slug === this.props.match.params.slug);

      const imagesCarousel = Object.keys(info.images)
        .map(key => {
          return (
            <Carousel.Item key={key}>
              <img
                className="d-block w-100"
                src={`../images/${info.images[key].name}`}
                alt={info.name}
              />
              <Carousel.Caption className={`bg-${info.images[key].bg} d-none d-sm-block`}>
                <p>{info.images[key].info}</p>
              </Carousel.Caption>
            </Carousel.Item>
          )
        })

      const moreInfo = info.infos;

      return (

        <div id="box-project" className="main-box p-5">
          <h1 className="title mb-3">{info.name}</h1>
          <div className="row flex-wrap-reverse flex-md-wrap">
            <div className="col-lg-6">
              <Carousel>
                {imagesCarousel}
              </Carousel>
            </div>
            <div className="col-lg-6">
              <div>
                <span className="subtitle">Description :</span>
                <span className="description">{info.description}</span>
              </div>
              <div className="d-flex justify-content-between my-2 flex-column flex-lg-row">
                <a className="text-center" href={info.depot} target="_blank" rel="noopener noreferrer">
                  <span className="subtitle">Dépot</span>
                  <FontAwesomeIcon icon={faGithub} />
                </a>
                {info.link ? <a className="text-center" href={info.link} target="_blank" rel="noopener noreferrer"><span className="subtitle">Voir l'application</span><FontAwesomeIcon icon={faGlobeEurope} /></a> : ''}
                <a className="text-center" href={info.feedback} target="_blank" rel="noopener noreferrer">
                  <span className="subtitle">Feedback</span>
                  <FontAwesomeIcon icon={faMarkdown} />
                </a>
              </div>
            </div>
          </div>
          {info.infos ? <div className="moreInfos" dangerouslySetInnerHTML={{ __html: moreInfo }} /> : ''}
        </div>
      );
    }
  }
}

export default Project;