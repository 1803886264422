import React from 'react';
import './App.css';
import Presentation from './components/Presentation';
import Experiences from './components/Experiences';
import Training from './components/Training';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';

function App() {
  return (
    <div className="main-box">
      <Presentation />
      <Experiences />
      <Training />
      <Skills />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
