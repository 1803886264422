import React, { Component } from 'react';
import { CardColumns } from 'react-bootstrap';

import CardProject from './CardProject';
import base from '../config/base';

import '../css/projects.css';

class Projects extends Component {
  state = {
    evaluations: {},
    projects: {},
  }

  componentDidMount() {
    this.ref = base.syncState('/evaluations', {
      context: this,
      state: 'evaluations'
    });

    this.ref = base.syncState('/projects', {
      context: this,
      state: 'projects'
    });
  }



  render() {
    const allProjects = Object.assign(this.state.evaluations, this.state.projects);
    const listProjects = Object.keys(allProjects)
      .map(key => {
        return (
        <CardProject
          key={key}
          item={allProjects[key]}
        />
      )});

    return (
      <div id="projects" className="box">
        <h1>Mes projets</h1>
        <CardColumns className="container-projects">
          {listProjects}
        </CardColumns>
      </div>
    );
  }
}

export default Projects;