import React from 'react';
import { Card } from 'react-bootstrap';

const CardSkills = ({ item }) => {
  return (
    <Card>
      <Card.Header>{item.name}</Card.Header>
      <Card.Body className="d-none d-md-block">
        {item.logo && <Card.Img src={`./images/skills/${item.logo}`} />}
      </Card.Body>
    </Card>
  )
}

export default CardSkills;