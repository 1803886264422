import React from 'react';
import { Card } from 'react-bootstrap';

const CardProject = ({ item }) => {

  const randomImage = image => {
    const listImages = Object.keys(image)
      .map(key => image[key]);
    
    const randomInt = Math.floor(Math.random() * (listImages.length));

    return (
      <a href={`${item.theme}/${item.slug}`} title="Avoir plus d'informations">
        <Card.Img variant="bottom" src={`./images/${listImages[randomInt].name}`} alt={item.name} className="border"/>
      </a>
    )
  }

  return (
    <Card>
      <Card.Header>{item.name}</Card.Header>
      <Card.Body>
        {item.description}
        {randomImage(item.images)}
      </Card.Body>
    </Card>
  )
}

export default CardProject;