import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFlask, faCertificate, faMedal, faTasks, faFilePdf, faTools } from '@fortawesome/free-solid-svg-icons';
import { faLeanpub } from '@fortawesome/free-brands-svg-icons';

import base, { firebaseApp } from '../config/base';

import logoHeader from './../images/logo.jpg';
import '../css/header.css';

class Header extends Component {
  state = {
    evaluations: {},
    projects: {},
    counter: null,
    visited: window.localStorage.getItem('visited')
  }

  componentDidMount = () => {
    this.ref = base.syncState('/evaluations', {
      context: this,
      state: 'evaluations'
    });

    this.ref = base.syncState('/projects', {
      context: this,
      state: 'projects'
    });


    firebaseApp.database().ref().on('value', (data) => {
      let counter = data.val() ? data.val().numberVisits : 0;
      counter++;
      this.setState({ counter: counter });
    })
  }

  componentDidUpdate = () => {
    if (!this.state.visited && this.state.counter) {
      this.sendCounter();
    }
  }


  sendCounter = () => {
    let counter = { numberVisits: this.state.counter }
    firebaseApp.database().ref().update(counter);
    window.localStorage.setItem('visited', true);
    this.setState({ visited: true });
  }

  render() {



    const listEvaluations = Object.keys(this.state.evaluations)
      .map(key => (
        <NavDropdown.Item
          key={key}
          href={`/${this.state.evaluations[key].theme}/${this.state.evaluations[key].slug}`}>
          <FontAwesomeIcon icon={faMedal} className="header-icon" />
          {this.state.evaluations[key].name}
        </NavDropdown.Item>
      ));

    const listProjects = Object.keys(this.state.projects)
      .map(key => (
        <NavDropdown.Item
          key={key}
          href={`/${this.state.projects[key].theme}/${this.state.projects[key].slug}`}>
          <FontAwesomeIcon icon={faTasks} className="header-icon" />
          {this.state.projects[key].name}
        </NavDropdown.Item>
      ));

    const projectText = (<><FontAwesomeIcon icon={faTools} className="header-icon" />Projets</>);

    return (
      <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
        <Navbar.Brand href="/#presentation">
          <img className="d-none d-lg-inline header-image" src={logoHeader} alt="Logo - Portfolio Cédric Pourrias" />
          <span>Cédric Pourrias</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link className="text-lg-center" href="/#presentation">
              <FontAwesomeIcon icon={faUser} className="header-icon" />
              Présentation
            </Nav.Link>
            <Nav.Link className="text-lg-center" href="/#experiences">
              <FontAwesomeIcon icon={faFlask} className="header-icon" />
              Expériences
            </Nav.Link>
            <Nav.Link className="text-lg-center" href="/#training">
              <FontAwesomeIcon icon={faLeanpub} className="header-icon" />
              Formations
            </Nav.Link>
            <Nav.Link className="text-lg-center" href='/#skills'>
              <FontAwesomeIcon icon={faCertificate} className="header-icon" />
              Skills
            </Nav.Link>
            <NavDropdown title={projectText} id="projects-nav-dropdown">
              <NavDropdown.Item href="/#projects">Liste des projets</NavDropdown.Item>
              <NavDropdown.Divider />
              {listEvaluations}
              <NavDropdown.Divider />
              {listProjects}
            </NavDropdown>
            <Nav.Link className="text-lg-center" href="/#contact">Me contacter</Nav.Link>
            <Nav.Link className="text-lg-center" href="./pdf/CedricPourriasResume.pdf" target="_blank" rel="noopener noreferrer" >
              <FontAwesomeIcon icon={faFilePdf} className="header-icon" />
              Mon CV
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;