import React, { Component } from 'react';
import { CardColumns } from 'react-bootstrap';

import base from '../config/base';
import CardSkills from './CardSkills';

import '../css/skills.css';

class Skills extends Component {
  state = {
    skills: {}
  }

componentDidMount() {
  this.ref = base.syncState('/skills', {
    context: this,
    state: 'skills'
  });
}

  render() {
    const {skills} = this.state;

    const sortByName = (a, b) => {
      if (this.state.skills[a].name.toUpperCase() < this.state.skills[b].name.toUpperCase()) {
        return -1;
      } else if (this.state.skills[a].name.toUpperCase() === this.state.skills[b].name.toUpperCase()) {
        return 0;
      } else {
        return 1;
      }
    }

    const listSkills = Object.keys(skills)
      .sort(sortByName)
      .map(key => (
        <CardSkills
          key={key}
          item={this.state.skills[key]}
        />
      ));

    return (
      <div id="skills" className="box">
        <h1>Compétences</h1>
        <CardColumns className="container-skills">
          {listSkills}
        </CardColumns>
      </div>
    );
  }
}

export default Skills;