import React, { Component, Fragment } from 'react';
import { Card, Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faEnvelope, faGlobeEurope, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedin, faYoutube, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';

class CardTraining extends Component {

  state = {
    isActive: false
  }

  showInfos = (id) => {
    this.setState({ isActive: true });
    window.location = `#${id}`;
  }

  hiddenInfos = (id) => {
    this.setState({ isActive: false })
    window.location = `#${id}`
  }

  render() {
    const { item, teachers, id } = this.props;

    const dateTraining = (item) => item.dateEnd ? `${item.dateStart} - ${item.dateEnd}` : `${item.dateStart}`;

    const listInfos = item.infos
      .split('\n')
      .map(item => <li key={item}>{item}</li>);

    const listSkills = item.skills
      .split(';')
      .sort()
      .map(item => <li key={item}><Badge pill variant="primary">{item}</Badge></li>);

    const showTeacher = (item, teachers) => {
      const teacher = Object.values(teachers).find(teacher => teacher.name === item.teacher);

      if (teacher) {
        const mail = teacher.mail ? `mailto:${teacher.mail}` : '';

        return (
          <div>
            <div>
              <em>Auteur :</em>
              <em className="mx-2">{teacher.name}</em>
            </div>
            <div>
              {teacher.mail && <a href={mail}><FontAwesomeIcon icon={faEnvelope} /></a>}
              {teacher.web && <a href={teacher.web} className="mx-2" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGlobeEurope} /></a>}
              {teacher.facebook && <a href={teacher.facebook} className="mx-2" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>}
              {teacher.twitter && <a href={teacher.twitter} className="mx-2" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>}
              {teacher.linkedin && <a href={teacher.linkedin} className="mx-2" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>}
              {teacher.youtube && <a href={teacher.youtube} className="mx-2" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>}
              {teacher.github && <a href={teacher.github} className="mx-2" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} /></a>}
            </div>
          </div>
        )
      }
    }

    return (
      <Card className={item.important ? 'border-danger' : ''} styleid={id}>
        <Card.Header className="d-flex align-items-center">
          <div className={item.logo ? 'w-75' : 'w-100'}>
            <div>{item.title}</div>
            <small className="text-muted">{dateTraining(item)}</small>
          </div>
          {item.logo && <Card.Img variant="top" src={`./images/training/${item.logo}`} alt={item.compagny} className="w-25 h-100" title={item.compagny} />}
        </Card.Header>
        <Card.Body>
          {!this.state.isActive &&
            <Button variant="outline-info" className="d-flex justify-content-center align-items-center" onClick={() => this.showInfos(id)}>
              <FontAwesomeIcon icon={faAngleDoubleDown} title="Découvrir plus d'informations" size="2x" />
              <span className="mx-2">Afficher les détails</span>
              <FontAwesomeIcon icon={faAngleDoubleDown} title="Découvrir plus d'informations" size="2x" />
            </Button>}
          {this.state.isActive && <Fragment>

            <ul className="list-unstyled mt-0">
              {listInfos}
            </ul>
            <a className="border-top pt-2" href={item.link} target="_blank" rel="noopener noreferrer">
              Voir la formation
            <sup className="ml-1"><FontAwesomeIcon icon={faLink} /></sup>
            </a>
            {item.teacher ? showTeacher(item, teachers) : ''}
          </Fragment>}
        </Card.Body>
        {this.state.isActive &&
          <Card.Footer>
            <ul className="xp-skills">
              {listSkills}
            </ul>
            <Button variant="link" className="text-right" onClick={() => this.hiddenInfos(id)}>
              Masquer la carte
            </Button>
          </Card.Footer>}
      </Card>
    )
  }
};

export default CardTraining;