import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFound from './components/NotFound';
import Project from './components/Project';
import Headers from './components/Header';

const Root = () => (
  <BrowserRouter>
    <Headers />
    <Switch>
      <Route exact path='/' component={App} />
      <Route path='/eval/:slug' component={Project} />
      <Route path='/project/:slug' component={Project} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
)

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
