// Copy the Config with firebase

const firebaseConfig = {
  apiKey: "AIzaSyDV7AHohjqO56Jh52Y3d7omjw4pcG3t5u0",
  authDomain: "portfolio-grekoss.firebaseapp.com",
  databaseURL: "https://portfolio-grekoss.firebaseio.com",
  projectId: "portfolio-grekoss",
  storageBucket: "portfolio-grekoss.appspot.com",
  messagingSenderId: "176993475210",
  appId: "1:176993475210:web:481c9e1584ac8d60242b73",
  measurementId: "G-6K3TC1J6X8"
};

export default firebaseConfig;