import React, { Component } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import { firebaseApp } from '../config/base';

import '../css/contact.css';

class Contact extends Component {
  state = {
    name: "",
    mail: "",
    title: "",
    content: ""
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    event.preventDefault();

    const { name, mail, title, content } = this.state;
    
    const messageKey = `message-${Date.now()}`;
    const message = {};
    message[`/messages/${messageKey}`] = { 
      name: name,
      mail: mail,
      title: title,
      content: content 
    }

    firebaseApp.database().ref().update(message);

    // reset
    this.setState({ name: "", mail: "", content: "", title: "" });

    // action button change text
    const button = document.getElementById('btn-sendMessage');
    const alert = document.getElementById('alert-sendMessage');
    button.classList.remove('d-flex');
    button.classList.add('d-none');
    alert.classList.remove('d-none');
  }

  render() {
    return (
      <div id="contact" className="box">
        <h1>Me contacter</h1>

        <form onSubmit={this.handleSubmit}>
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="text-muted mb-3 mt-5 text-center">
                Vous souhaitez échanger avec moi sur diverses projets ou un nouveau défi à relever ? <br />
                Rien de plus simple, contactez-moi grâce à ce formulaire &#x1F609;
              </div>
              <InputGroup className="mb-3 mt-5">
                <InputGroup.Prepend>
                  <InputGroup.Text id="name">Votre nom</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="name"
                  placeholder="Saisir votre nom"
                  aria-label="name"
                  aria-describedby="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="title">Titre du message</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="title"
                  placeholder="Saisir un titre"
                  aria-label="title"
                  aria-describedby="title"
                  value={this.state.title}
                  onChange={this.handleChange}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="title">Votre adresse mail</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="mail"
                  placeholder="Saisir une adresse mail"
                  aria-label="mail"
                  aria-describedby="mail"
                  value={this.state.mail}
                  onChange={this.handleChange}
                />
              </InputGroup>
            </div>
            <div className="col-lg-5">
              <InputGroup className="mb-3 mt-5">
                <InputGroup.Prepend>
                  <InputGroup.Text>Message</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="textarea"
                  placeholder="Saisir votre message"
                  name="content"
                  aria-label="content"
                  aria-describedby="content"
                  value={this.state.content}
                  onChange={this.handleChange}
                  rows={10}
                />
              </InputGroup>
            </div>
          </div>
          <button id="btn-sendMessage" className="btn btn-success d-flex m-auto p-4" type="submit">Envoyer le message</button>
          <div id="alert-sendMessage" className="d-none alert alert-dark text-center mt-4" role="alert">
            Votre message a été envoyé !
          </div>
        </form>

        <div className="footer-contact text-center mt-5">
          <p className="text-white">Ou sur mes réseaux :</p>
          <ul>
            <li>
              <a href="https://www.linkedin.com/in/cedricpourrias/" target="_blank" rel="noopener noreferrer" title="LinkedIn">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </li>
            <li>
              <a href="mailto:contact@cedricpourrias.fr" title="Mail">
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </li>
            <li>
              <a href="tel:+33623695097" title="Numéro de téléphone">
                <FontAwesomeIcon icon={faPhone} />
              </a>
            </li>
            <li>
              <a href="https://github.com/Grekoss" target="_blank" rel="noopener noreferrer" title="GitHub">
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Contact;