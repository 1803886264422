import React, { Component } from 'react';

import CardExperience from './CardExperience';
import base from '../config/base';

import '../css/experiences.css';

class Experiences extends Component {
  state = {
    experiences: {}
  };

  componentDidMount() {
    this.ref = base.syncState('/experiences', {
      context: this,
      state: 'experiences'
    });
  }

  render() {
    const {experiences} = this.state;

    const listExperiences = Object.keys(experiences)
      .reverse()
      .map(key => (
        <CardExperience
          key={key}
          details={this.state.experiences[key]}
        />
      ));

    return (
      <div id="experiences" className="box">
        <h1>Expériences</h1>
        <div className="container-xp">
          {listExperiences}
        </div>
      </div>
    );
  }
}

export default Experiences;